@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Oswald', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #F8FFE5 !important;
}

.footer-container {
  padding-top: 40px;
  text-align: center;
}

.no-link-decoration {
  text-decoration: none;
  color:inherit;
}

.ul-no-list-decoration {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.about-container {
  padding-top: 40px;
  text-align: center;
}

.current-project-card {
  background:#0C120C !important;
  width: '18rem';
  color: white !important;
}

.card-button {
  background:#E57A44 !important;
  border-color: #E57A44 !important;
  transition-duration: 0.4s;
}

.card-button:hover {
  background-color: gray !important;
  border-color: gray !important;
}

.card-link {
  color: #E57A44;
}




